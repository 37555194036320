const USER_COMPLETE =
  'https://res.cloudinary.com/pulsedatatools/image/upload/v1694443590/polaris/icons/user_complete.svg'
const USER_FAILED =
  'https://res.cloudinary.com/pulsedatatools/image/upload/v1694545856/polaris/icons/user_failed.svg'

const LOGGED_IN_ICON = {
  icon: USER_COMPLETE,
  toolTip: 'User has logged into Polaris.',
}
const NOT_LOGGED_IN_ICON = {
  icon: USER_FAILED,
  toolTip: 'User has not logged into Polaris.',
}

export const getLoginStatus = (loginsCount: number) => {
  const hasLoggedIn = loginsCount > 0

  if (hasLoggedIn) return LOGGED_IN_ICON

  return NOT_LOGGED_IN_ICON
}
