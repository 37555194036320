import React from 'react'
import _ from 'lodash'

import { Select } from 'frontend/components'
import { INSTRUCTION_STATUS_MAP } from 'frontend/PayerPolicyLinkMgmt/v1/Table/Policies/column-config/getLinkAutomationDetailsColumns'
import FieldLabel from '../FieldLabel'

interface Props {
  instruction: any
  policyState: any
  setPolicyState: any
}

const InstructionStatus = ({
  instruction,
  policyState,
  setPolicyState,
}: Props) => {
  const { options, value } = getOptionsValue(instruction, policyState)

  const handleOnChange = (pickedOption: { label: string; value: string }) =>
    setPolicyState((prevState: any) => ({
      ...prevState,
      instruction_status: pickedOption.value,
    }))

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px 0px' }}>
      <FieldLabel>Instruction Status</FieldLabel>
      <Select options={options} value={value} onChange={handleOnChange} />
    </div>
  )
}

export default InstructionStatus

const getOptionsValue = (instruction: any, policyState: any) => {
  const instruction_status: keyof typeof INSTRUCTION_STATUS_MAP =
    (policyState?.instruction_status as keyof typeof INSTRUCTION_STATUS_MAP) ||
    (instruction.instruction_status as keyof typeof INSTRUCTION_STATUS_MAP) ||
    'no_automation'

  const instructionStatusOptions = Object.entries(
    INSTRUCTION_STATUS_MAP
  ).map(([key, value]) => ({ value: key, label: value.value }))

  const currentInstructionStatusOption = {
    value: instruction_status,
    label:
      INSTRUCTION_STATUS_MAP[
        policyState?.instruction_status as keyof typeof INSTRUCTION_STATUS_MAP
      ]?.value || INSTRUCTION_STATUS_MAP[instruction_status]?.value,
  }

  return {
    options: instructionStatusOptions,
    value: currentInstructionStatusOption,
  }
}
