import React from 'react'

import { Select } from 'frontend/components'

import { ORGANIZATION_OPTIONS } from '../../useColConfig'
import { Label, Wrapper } from './shared'

interface Props {
  fieldState: any
  onChange: any
}

const LABEL_TEXT = 'Organization'
const FIELD_NAME = 'app_metadata.organization'

export const Organization = ({ fieldState, onChange }: Props) => {
  const { app_metadata } = fieldState
  const organization = app_metadata?.organization
  const defaultValue = ORGANIZATION_OPTIONS.find(
    ({ value }: any) => value === organization
  )

  const handleChange = ({ value }: any) => {
    onChange({
      name: FIELD_NAME,
      value,
    })
  }

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Select
        options={ORGANIZATION_OPTIONS}
        value={defaultValue}
        onChange={handleChange}
      />
    </Wrapper>
  )
}
