import Button from 'frontend/components/Button'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'
import { useClearSearchStringParam } from 'frontend/hooks'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  backgroundColor: Colors.WHITE,
  boxShadow: '0px -3px 5px rgba(0, 0, 0, 0.05)',
  padding: `16px 24px`,
  position: 'absolute',
  bottom: 0,
  width: '100%',
})

const ButtonWrapper = styled.div({
  display: 'flex',
  gap: '12px',
})

export const ActionBar = ({ handleSubmit, handleCancel, disableSubmit }) => {
  const submit = () => {
    handleSubmit()
    handleCancel()
  }

  return (
    <Wrapper>
      <ButtonWrapper>
        <Button type="secondary" onClick={handleCancel} color={Colors.GRAY_500}>
          Cancel
        </Button>
        <Button type="primary" onClick={submit} isDisabled={disableSubmit}>
          Save
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}
