import React, { MutableRefObject, useRef } from 'react'

import { SlidingPanel } from 'frontend/components'
import { useClient } from 'frontend/entity-hooks/ember'
import { useQueryString, useClearSearchStringParam } from 'frontend/hooks'

import { getFormConfig } from './getFormConfig'

const HEADER_TEXT = 'Edit Client'

export const EditClientSidePanel = () => {
  const clearSearchStringParam = useClearSearchStringParam()
  const { client: selectedClient } = useQueryString()
  const {
    update,
    isLoading,
    data: client,
  }: { update: any; isLoading: Boolean; data: any } = useClient({
    queryInput: { id: selectedClient },
  })
  const inputRef: MutableRefObject<any> = useRef({})

  const handleSubmit = () => {
    const input = inputRef.current
    update({ variables: { input: { id: selectedClient, ...input } } })
  }
  const handleCancel = () => {
    clearSearchStringParam(['sidePanel'])
  }

  const setInputRef = ({ name, value }: any) => {
    inputRef.current[name] = value
  }

  const formConfig = getFormConfig()

  return (
    <SlidingPanel
      header={HEADER_TEXT}
      formConfig={formConfig}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      setInputRef={setInputRef}
      isLoading={isLoading}
      datum={client[0]}
    />
  )
}
