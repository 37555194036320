import React, { MutableRefObject, useRef } from 'react'
import { DataProxy } from '@apollo/client'

import { GET_TEAMS } from 'frontend/entity-hooks/ember/useTeam/gql-tags'
import { SlidingPanel } from 'frontend/components'
import { useClient, useTeam } from 'frontend/entity-hooks/ember'
import { useClearSearchStringParam } from 'frontend/hooks'

import { getClientOptions } from '../utils/getClientOptions'
import { getFormConfig } from './getFormConfig'

const HEADER_TEXT = 'Create Team'

export const CreateTeamSidePanel = () => {
  const clearSearchStringParam = useClearSearchStringParam()
  const { data: clients, isLoading: areClientsLoading } = useClient()
  const {
    create,
    options: clientSourceOptions,
    areOptionsLoading,
  }: { create: any; options: any; areOptionsLoading: Boolean } = useTeam()

  const inputRef: MutableRefObject<any> = useRef({})
  const handleSubmit = () => {
    const input = inputRef.current
    create({
      variables: { input },
      update: (cache: DataProxy, data: any) => {
        const createEntity: any = Object.values(data.data)[0]
        const variables = { input: { client: createEntity.client } }

        const query: any = cache.readQuery({
          query: GET_TEAMS,
          variables,
        })

        // only prepend if the cache exists
        if (!query) return
        const queryName = Object.keys(query)[0]

        cache.writeQuery({
          query: GET_TEAMS,
          variables,
          data: {
            [queryName]: [createEntity, ...query[queryName]],
          },
        })
      },
    })
  }
  const handleCancel = () => {
    clearSearchStringParam(['sidePanel'])
  }

  const setInputRef = ({ name, value }: any) => {
    inputRef.current[name] = value
  }

  const clientOptions = getClientOptions(clients, areClientsLoading)
  const formConfigOptions = {
    clientOptions,
    clientSourceOptions: clientSourceOptions.client_source?.options ?? [],
  }
  const formConfig = getFormConfig(formConfigOptions)

  const isLoading = areOptionsLoading || areClientsLoading

  return (
    <SlidingPanel
      header={HEADER_TEXT}
      formConfig={formConfig}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      setInputRef={setInputRef}
      isLoading={isLoading}
    />
  )
}
