const Color = {
  PRIMARY: '#0668D9',
  BLACK: '#0A2E4D',
  WHITE: '#FFFFFF',
  BLUE: '#0668D9',
  GREEN: '#22BF6B',
  RED: '#EE5340',
  ORANGE: '#FD9651',
  YELLOW: '#FFDC6C',
  PURPLE: '#7C5EFA',
  // Old Grays
  LIGHT_GRAY_1: '#DFE3EA',
  LIGHT_BLUE_GRAY_1: '#F0F6F9',
  LIGHT_BLUE_GRAY_2: '#EEF4FA',
  MEDIUM_GRAY_1: '#CDD8E6',
  MEDIUM_GRAY_2: '#A3BBDA',
  // New Grays
  GRAY_DARK: '#849CB2',
  GRAY_MEDIUM: '#BBC8D6',
  GRAY_LIGHT: '#F1F5FA',
  // Background Colors
  MAIN_BACKGROUND: '#F1F7FD',
  // Custom Colors
  PHOENIX: '#FA8969',
  ORION: '#38B59A',
  DELPHI: '#7C5EFA',
  PAYER_PROJECTS: '#30B2DC',
  TOOL_SIDEBAR: '#093357',
  FLAT_FILE_EXPORT: `#04305D`,
  REPORTS: `#D0021B`,
  ADMIN: '#4493F2',
}

export default Color
