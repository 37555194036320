import React from 'react'
import styled from '@emotion/styled'

import { useEmberNode } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'
import { Colors } from 'frontend/utils/pulseStyles'

import { ParentType } from 'frontend/ClientManagement/Sitemap/types'

interface Props {
  parentType: ParentType
}

const Wrapper = styled.span({
  color: Colors.COOL_GRAY_500,
})

const ParentTitle = ({
  parentType,
}: Props) => {
  const { [parentType]: id } = useQueryString()

  const {
    data,
    isLoading,
  }: any = useEmberNode({
    queryInput: {
      type: parentType,
      id,
    }
  })

  const text = isLoading ? null : `(${data[0].name})`

  return (
    <Wrapper>
      {text}
    </Wrapper>
  )
}

export default ParentTitle
