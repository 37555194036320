import _ from 'lodash'

import useRead from './useRead'
import useCreate from './useCreate'
import useDelete from './useDelete'
import useUpdate from './useUpdate'
import useResetPassword from './useResetPassword'
import { useAuth0 } from 'react-auth0-spa'
import { useFlags } from 'launchdarkly-react-client-sdk'

/**
 * usePolarisUsers is a custom hook that returns the current user, all users, and functions to create, delete, and update users
 * @returns {Object} - An object containing the current user, all users, and functions to create, delete, and update users
 */
export const usePolarisUsers = () => {
  const { user } = useAuth0()
  const { shouldShowAdminModule } = useFlags()
  const { data: polarisUsersData, loading: isLoading } = useRead()
  const [create, { loading: isCreating }] = useCreate()
  const [destroy, { loading: isDeleting }] = useDelete()
  const [update, { loading: isUpdating }] = useUpdate()
  const [resetPassword, { loading: isResettingPassword }] = useResetPassword()

  const data = polarisUsersData?.polarisUsers || []
  let currentUser: {
    [key: string]: any
    isAdmin?: boolean
    getIsPermittedAccess: (resource: string) => boolean
  } = data.find(({ user_id }: any) => user_id === user?.sub) || {}
  currentUser = _.cloneDeep(currentUser)

  const getIsPermittedAccess = (resource: string) => {
    // ! Skip permissions entirely until permissions feature flag release
    // TODO: REMOVE ON RELEASE
    if (!shouldShowAdminModule) return true

    if (!currentUser) return false

    const role = currentUser?.app_metadata?.role
    const permitted_modules = currentUser?.app_metadata?.permitted_modules

    if (!role) return false

    if (role === 'admin') return true

    if (_.isEmpty(permitted_modules)) return false

    return permitted_modules.includes(resource)
  }

  currentUser.isAdmin = currentUser?.app_metadata?.role === 'admin'
  currentUser.getIsPermittedAccess = getIsPermittedAccess

  return {
    currentUser,
    data,
    isLoading,
    create,
    isCreating,
    delete: destroy,
    isDeleting,
    isUpdating,
    update,
    resetPassword,
    isResettingPassword,
  }
}
