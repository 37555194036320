import { ModuleConfig } from 'frontend/constants'
import Color from 'frontend/utils/color'

import NcrDataValidator from './NcrDataValidator'

export const config: ModuleConfig = {
  title: 'NCR Data Validator',
  description: 'Validate NCR Data',
  iconColor: Color.PAYER_PROJECTS,
  name: 'ncr_data_validator',
  to: '/ncr-data-validator',
  exact: true,
  iconId: 'taurus-1-white',
  activeColor: Color.PAYER_PROJECTS,
  featureFlag: 'shouldShowNcrValidatorTool',
  isAdminOnly: true,
  Component: NcrDataValidator,
}
