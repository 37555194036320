import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { usePolarisUsers } from 'frontend/api/usePolarisUsers'

import PolarisUserManagement from './PolarisUserManagement'
import ListsConfigManagement from './ListsConfigManagement'
import SheetManagement from './SheetManagement'
import NodeManagement from './NodeManagement'
import DevToProdPushManagement from './DevToProdPushManagement'
import BusinessObject from './BusinessObject'
import EndUserTerms from './EndUserTerms'
import TotalHistory from './TotalHistory'
import EditRoleNode from './EditRoleNode'
import Sidebar from './Sidebar'

const Administrator = () => {
  const { currentUser } = usePolarisUsers()

  if (!currentUser.isAdmin) return null

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <Sidebar />
      <Switch>
        <Route
          path={'/administrator/polaris-user-mgmt'}
          component={PolarisUserManagement}
        />
        <Route
          path={'/administrator/lists-config-mgmt'}
          component={ListsConfigManagement}
        />
        <Route path={'/administrator/sheet-mgmt'} component={SheetManagement} />
        <Route
          path={'/administrator/node-management'}
          component={NodeManagement}
        />
        <Route
          path={'/administrator/push-dev-prod-mgmt'}
          component={DevToProdPushManagement}
        />
        <Route
          path={'/administrator/business-object'}
          component={BusinessObject}
        />
        <Route
          path={'/administrator/edit-role-node'}
          component={EditRoleNode}
        />
        <Route
          path={'/administrator/user-term-mgmt'}
          component={EndUserTerms}
        />
        <Route path={'/administrator/total-history'} component={TotalHistory} />
        <Redirect to={'/administrator/polaris-user-mgmt'} />
      </Switch>
    </div>
  )
}

export default Administrator
