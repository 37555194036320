import { ModuleConfig } from 'frontend/constants'
import Color from 'frontend/utils/color'

import PayerPolicyLinkMgmt from './PayerPolicyLinkMgmt'

export const config: ModuleConfig = {
  title: 'Payer Policy Tracker',
  description: 'Track and Manage Payer Policy Links',
  iconColor: Color.PAYER_PROJECTS,
  name: 'payer_policy_v0',
  to: '/payer-policy',
  exact: true,
  iconId: 'payertracking-1-white',
  activeColor: Color.PAYER_PROJECTS,
  Component: PayerPolicyLinkMgmt,
}
