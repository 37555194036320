import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'
import { formatMonthDayYearSlash } from 'frontend/utils/formatDate'

import LoginStatus from './LoginStatus'

interface Props {
  user: any
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 24px 24px 24px',
})

const StyledHeader = styled.label({
  fontSize: 16,
  fontWeight: 400,
  marginBottom: 8,
})

const Subheader = styled.label({
  fontSize: 11,
  fontWeight: 400,
  fontStyle: 'italic',
  color: Colors.COOL_GRAY_600,
})

const Header = ({ user }: Props) => {
  const { name, last_login } = user || {}

  const headerText = `Edit User: ${name}`
  const subheaderText = last_login
    ? `Last Login: ${formatMonthDayYearSlash(last_login)}`
    : 'Last Login: N/A'

  return (
    <Wrapper>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <StyledHeader>{headerText}</StyledHeader>
        <LoginStatus user={user} />
      </div>
      <Subheader>{subheaderText}</Subheader>
    </Wrapper>
  )
}

export default Header
