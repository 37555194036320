import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { usePermittedModules } from 'frontend/hooks'
import { ModuleConfig } from 'frontend/constants'

import Home from '../Home'
import { usePolarisUsers } from '../api/usePolarisUsers'
import PayerLivesImport from '../PayerProjects/PayerLivesImport'
import PayerProject from '../PayerProjects/PayerProject'

export const ModuleRoutes = () => {
  const permittedModules = usePermittedModules()
  const { currentUser } = usePolarisUsers()
  const isAdmin = currentUser?.isAdmin

  const moduleRoutes = permittedModules.map(
    ({ to, exact, Component }: ModuleConfig) => (
      <Route key={to} exact={exact} path={to} component={Component} />
    )
  )

  const completeModuleRoutes = [
    <Route exact path="/" component={Home} />,
    ...moduleRoutes,
    isAdmin ? (
      <Route path="/payer-projects/import-lives" component={PayerLivesImport} />
    ) : null,
    <Route path="/payer-projects/:projectId" component={PayerProject} />,
    <Redirect to="/" />,
  ]

  return <Switch>{completeModuleRoutes}</Switch>
}
