import React from 'react'
import styled from '@emotion/styled'

import LoginStatus from './LoginStatus'

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  fontSize: 20,
  fontWeight: 600,
  padding: 24,
})

const HEADER_TEXT = 'Edit User:'

const Header = ({ user }: any) => {
  if (!user) return null

  return (
    <Wrapper>
      <div>
        {HEADER_TEXT} {user.firstName} {user.lastName}
      </div>
      <LoginStatus auth0Id={user?.id} />
    </Wrapper>
  )
}

export default Header
