import React, { useState } from 'react'
import Select from 'react-select'

import PermissionTeam from './PermissionTeam'
import IndicationProduct from './IndicationProduct'
import ProviderChannel from './ProviderChannel'

import NcrFiltersAirflowButton from './NcrFiltersAirflowButton'
import { Colors } from 'frontend/utils/pulseStyles'

const DROPDOWN_OPTIONS = [
  {
    value: 'Validate Team Permissions',
    label: 'Validate Team Permissions',
    Component: PermissionTeam,
  },
  {
    value: 'Validate Provider Channel - Products',
    label: 'Validate Provider Channel - Products',
    Component: ProviderChannel,
  },
  {
    value: 'Validate Products',
    label: 'Validate Products',
    Component: IndicationProduct,
  },
]

const HackDay = () => {
  const [selectedOption, setSelectedOption] = useState(DROPDOWN_OPTIONS[0])
  const { Component } = selectedOption

  return (
    <div style={{ width: '100%', padding: 12, overflow: 'auto' }}>
      <h1 style={{ padding: 12 }}>NCR Import Validator</h1>
      <div
        style={{
          display: 'flex',
        }}
      >
        <section style={{ width: '50%', padding: 12 }}>
          <h3>What would you like to do?</h3>
          <Select
            value={selectedOption}
            options={DROPDOWN_OPTIONS}
            onChange={setSelectedOption}
          />
        </section>
        <section
          style={{
            width: '50%',
            padding: 12,
            backgroundColor: Colors.COOL_GRAY_100,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <NcrFiltersAirflowButton />
        </section>
      </div>
      <Component />
    </div>
  )
}

export default HackDay
