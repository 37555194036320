import React from 'react'
import styled from '@emotion/styled'

import { Type } from 'frontend/ClientManagement/Sitemap/types'
import CopyButton from './CopyButton'
import EditButton from './EditButton'

interface Props {
  isHovered: boolean
  isSelected: boolean
  type: Type
  data: any
}

const Wrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
})

const ButtonGroup = ({
  isHovered,
  isSelected,
  data,
  ...props
}: Props) => {
  if (!isHovered && !isSelected) return null

  return (
    <Wrapper>
      <CopyButton
        data={data}
      />
      <EditButton
        data={data}
        {...props}
      />
    </Wrapper>
  )
}

export default ButtonGroup
