import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { usePermittedModules } from 'frontend/hooks'
import { ModuleConfig } from 'frontend/constants'
import Icon from 'frontend/components/Icon'
import Color from 'frontend/utils/color'
import { Colors, Spacing } from 'frontend/utils/pulseStyles'

const ICON_SIZE = 20

const StyledNavLink = styled(NavLink as any)<{ exact?: boolean; to: string }>({
  color: Color.WHITE,
  borderRadius: 4,
  textDecoration: 'none',
  padding: `${Spacing.NORMAL} ${Spacing.NORMAL} ${Spacing.SMALL}`,
  margin: 8,
  opacity: 0.4,
  ':hover': {
    background: transparentize(0.9, Colors.WHITE),
  },
})

const HOME_CONFIG = {
  name: 'Home',
  to: '/',
  exact: true,
  iconId: 'home-1-white',
  activeColor: Color.PRIMARY,
}

const HOME_SIDEBAR_ITEM = (
  <StyledNavLink
    key={HOME_CONFIG.to}
    exact={HOME_CONFIG.exact}
    to={HOME_CONFIG.to}
    activeStyle={{
      opacity: 1,
      background: transparentize(0.85, HOME_CONFIG.activeColor as string),
    }}
  >
    <Icon width={ICON_SIZE} height={ICON_SIZE} iconName={HOME_CONFIG.iconId} />
  </StyledNavLink>
)


export const ModuleSidebar = () => {
  const permittedModules = usePermittedModules()

  const moduleSidebarLinks = permittedModules.map(
    ({ to, iconId, exact, activeColor }: ModuleConfig) => (
      <StyledNavLink
        key={to}
        exact={exact}
        to={to}
        activeStyle={{
          opacity: 1,
          background: transparentize(0.85, activeColor as string),
        }}
      >
        <Icon width={ICON_SIZE} height={ICON_SIZE} iconName={iconId} />
      </StyledNavLink>
    )
  )

  const completeSidebarLinks = [HOME_SIDEBAR_ITEM, ...moduleSidebarLinks]

  return <>{completeSidebarLinks}</>
}
