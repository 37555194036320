import React from 'react'
import _ from 'lodash'

import { TextInput } from 'frontend/components'

import { Label, Wrapper } from '../shared'
import { CheckboxList } from './CheckboxList'

interface Props {
  fieldState: any
  onChange: any
}

const LABEL_TEXT = 'Modules'
const FIELD_NAME = 'app_metadata.permitted_modules'

export const Modules = ({ fieldState, onChange }: Props) => {
  const selectedModules = _.get(fieldState, FIELD_NAME, [])
  const handleChange = ({ value }: any) => {
    onChange({ name: FIELD_NAME, value })
  }

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <CheckboxList selectedModules={selectedModules} onChange={handleChange} />
    </Wrapper>
  )
}
