import React from 'react'

import { useQueryString } from 'frontend/hooks'

import { SidePanel } from './SidePanel'

const Container = () => {
  const { sidePanel }: { sidePanel?: boolean } = useQueryString()

  if (!sidePanel) return null

  return <SidePanel />
}

export default Container
