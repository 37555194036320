import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'

import FieldTypeInput from './FieldTypeInput'
import BusinessObjectValidationForm from './BusinessObjectValidationForm'

import {
  FormLabel,
  FieldContainer,
  FieldsFormContainer,
  StyledInput,
  StyledTextarea,
  StyledButton,
} from '../../shared/styledComponents'

import { GET_WORKBOOKS } from 'frontend/api/queries'

const Form = ({
  data,
  afterMutationHook,
  mutationDoc,
  mutationVars,
  closeModal,
}) => {
  const [stagedFieldName, setFieldName] = useState(data.name)
  const [stagedType, setType] = useState(data.type)
  const [stagedOneOf, setOneOf] = useState(
    Array.isArray(data.oneOf)
      ? JSON.stringify(data.oneOf).replace(/\[|\]/g, '')
      : ''
  )

  let defaultStagedBusinessObjRef = null
  if (data.businessObjRef) {
    const { __typename, ...rest } = data.businessObjRef
    defaultStagedBusinessObjRef = rest
  }

  const [stagedBusinessObjRef, setBusinessObjRef] = useState(
    defaultStagedBusinessObjRef
  )

  const [saveField] = useMutation(mutationDoc, {
    variables: {
      input: {
        ...mutationVars,
        name: stagedFieldName,
        type: stagedType,
        oneOf: stagedOneOf,
        businessObjRef: stagedBusinessObjRef,
      },
    },
    refetchQueries: [{ query: GET_WORKBOOKS }],
    onCompleted: (result) => {
      const targetDataKey = Object.keys(result)[0]
      const newOrUpdatedField = result[targetDataKey]

      closeModal()
      afterMutationHook(newOrUpdatedField)
    },
    onError: (e) => alert(`Write failure: ${e.message}`),
    awaitRefetchQueries: true,
  })

  const handleOneOfChange = (e) => {
    e.persist()
    const value = e.currentTarget && e.currentTarget.value
    setOneOf(value)
  }

  const handleFieldChange = (e) => {
    e.persist()
    const value = e.currentTarget && e.currentTarget.value
    setFieldName(value)
  }

  return (
    <FieldsFormContainer>
      <FieldContainer>
        <FormLabel>Field Name</FormLabel>
        <StyledInput
          type="text"
          value={stagedFieldName}
          onChange={handleFieldChange}
        />
      </FieldContainer>

      <FieldTypeInput stagedType={stagedType} setType={setType} />

      <FieldContainer>
        <FormLabel>oneOf Restrictions</FormLabel>
        <StyledTextarea value={stagedOneOf} onChange={handleOneOfChange} />
      </FieldContainer>

      <BusinessObjectValidationForm
        stagedBusinessObjRef={stagedBusinessObjRef}
        setBusinessObjRef={setBusinessObjRef}
      />

      <StyledButton onClick={saveField}>Save Field</StyledButton>
    </FieldsFormContainer>
  )
}

Form.propTypes = {
  data: PropTypes.object,
  mutationDoc: PropTypes.object,
  afterMutationHook: PropTypes.func,
  closeModal: PropTypes.func,
  mutationVars: PropTypes.object,
}

Form.defaultProps = {
  data: {},
  mutationDoc: null,
  afterMutationHook: () => {},
  closeModal: () => {},
}

export default Form
