import { DataProxy } from '@apollo/client'
import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import { SlidingPanel } from 'frontend/components'
import { useClient, useTeam, useEmberUser } from 'frontend/entity-hooks/ember'
import { useQueryString, useClearSearchStringParam } from 'frontend/hooks'

import {
  formatInput,
  getDefaultFieldState,
  addUserToTeamCache,
  removeUserFromTeamCache,
} from './utils'
import { UserForm } from './UserForm'

import Header from './Header'

export const EditUserSidePanel = () => {
  const clearSearchStringParam = useClearSearchStringParam()
  const { user: selectedUser }: any = useQueryString()
  const { isLoading: areClientsLoading } = useClient()
  const {
    options: clientSourceOptions,
    areOptionsLoading,
  }: {
    create: any
    options: any
    areOptionsLoading: Boolean
  } = useTeam()
  const {
    data: users,
    update,
    isLoading: isUserLoading,
  }: {
    data: any
    update: any
    isLoading: boolean
  } = useEmberUser({
    queryInput: { user: selectedUser },
  })

  const [user] = users
  const [fieldState, setFieldState] = useState<any>()

  let disableSubmit = true
  if (fieldState) {
    const {
      firstName,
      lastName,
      primaryTeam,
      username,
      email,
      teams,
      clientSources,
    }: any = fieldState
    disableSubmit =
      teams.length === 0 ||
      !clientSources ||
      !primaryTeam ||
      !username ||
      !lastName ||
      !firstName ||
      !email
  }

  useEffect(() => {
    if (!isUserLoading) {
      setFieldState(getDefaultFieldState(user))
    }
  }, [isUserLoading])

  const handleSubmit = () => {
    const input = formatInput(fieldState)
    update({
      variables: { input },
      update: (cache: DataProxy, data: any) => {
        const updatedUser: any = Object.values(data.data)[0]
        const { teams: initialTeams } = user
        const { teams: newTeams } = updatedUser

        const initialTeamIds = initialTeams.map(({ id }: any) => id)
        const newTeamIds = newTeams.map(({ id }: any) => id)
        const addedTeamIds: string[] = _.difference(newTeamIds, initialTeamIds)
        const removedTeamIds: string[] = _.difference(
          initialTeamIds,
          newTeamIds
        )

        for (const addedTeamId of addedTeamIds) {
          addUserToTeamCache(addedTeamId, updatedUser, cache)
        }
        for (const removedTeamId of removedTeamIds) {
          removeUserFromTeamCache(removedTeamId, updatedUser, cache)
        }
      },
    })
  }

  const handleCancel = () => {
    clearSearchStringParam(['sidePanel'])
  }

  const handleFieldChange = ({ name, value }: any) => {
    _.set(fieldState, name, value)
    const newFieldState = {
      ...fieldState,
    }
    setFieldState(newFieldState)
  }

  const isLoading = areOptionsLoading || areClientsLoading || !fieldState
  const formattedClientSourceOptions =
    clientSourceOptions.client_source?.options ?? []

  const header = <Header user={user} loginStatus="active" />

  return (
    <SlidingPanel
      header={header}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      isLoading={isLoading}
      disableSubmit={disableSubmit}
    >
      <UserForm
        fieldState={fieldState}
        clientSourceOptions={formattedClientSourceOptions}
        onChange={handleFieldChange}
        setFieldState={setFieldState}
      />
    </SlidingPanel>
  )
}
