import { useMutation } from '@apollo/client'

import readTag from '../useRead/gql-tag'
import tag from './gql-tag'

const useUpdate = () =>
  useMutation(tag, {
    onError: alert,
    update,
  })

export default useUpdate

const update = (
  cache: any,
  { data: { updatePolarisUser } }: { data?: any }
) => {
  const { polarisUsers } = cache.readQuery({
    query: readTag,
  })

  const updatedPolarisUsers = polarisUsers.map((user: any) => {
    if (user.user_id === updatePolarisUser.user_id) {
      return { ...user, ...updatePolarisUser }
    }
    return user
  })

  cache.writeQuery({
    query: readTag,
    data: {
      polarisUsers: [...updatedPolarisUsers],
    },
  })
}
