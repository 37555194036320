import React from 'react'
import styled from '@emotion/styled'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { transparentize } from 'polished'
import PropTypes from 'prop-types'

import { Colors, Spacing } from '../../utils/pulseStyles'

import NavigationLink from './NavigationLink'
import Dropdown from './Dropdown'

const NavigationLinkContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const Header = styled.div({
  padding: '24px 24px 12px',
  fontSize: 14,
  fontWeight: 700,
  color: transparentize(0.7, Colors.WHITE),
  textTransform: 'uppercase',
  letterSpacing: 0,
})

// * recursively nest link items
const getNestedLinkItems = (
  listConfig,
  FLAGS,
  isSuperUser,
  parentLink = null
) => {
  const data = []

  listConfig.forEach(
    ({ label, link, featureFlag, showOnSuperUserOnly, childLinks }) => {
      const combinedLink = parentLink ? `${parentLink}${link}` : link

      const nestedLinks = childLinks
        ? getNestedLinkItems(childLinks, FLAGS, isSuperUser, combinedLink)
        : null

      let component = childLinks ? (
        <Dropdown key={label} label={label}>
          {nestedLinks}
        </Dropdown>
      ) : (
        <NavigationLink key={label} link={combinedLink} label={label} />
      )

      if (featureFlag) {
        if (featureFlag === true || FLAGS[featureFlag]) data.push(component)
      } else if (showOnSuperUserOnly) {
        if (isSuperUser) data.push(component)
      } else {
        data.push(component)
      }
    }
  )

  return data
}

// * will also supported non-nested links!
const NavigationLinks = ({ sectionHeader, linkConfig, isSuperUser }) => {
  const FLAGS = useFlags()

  const links = getNestedLinkItems(linkConfig, FLAGS, isSuperUser)

  return (
    <NavigationLinkContainer>
      <Header>{sectionHeader}</Header>
      {links}
    </NavigationLinkContainer>
  )
}

NavigationLinks.propTypes = {
  sectionHeader: PropTypes.string,
  linkConfig: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      showOnSuperUserOnly: PropTypes.bool,
      childLinks: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          link: PropTypes.string,
          showOnSuperUserOnly: PropTypes.bool,
          childLinks: PropTypes.array,
        })
      ),
    })
  ),
  isSuperUser: PropTypes.bool,
}

NavigationLinks.defaultProps = {
  sectionHeader: 'Section Header',
  linkConfig: [{ label: '', link: '', childLinks: null }],
  isSuperUser: false,
}

export default NavigationLinks
