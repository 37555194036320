import gql from 'graphql-tag'

export const UPDATE_PULSE_DEV_NCR_FILTERS = gql`
  mutation UpdatePulseDevNcrFilters($input: UpdatePulseDevNcrFiltersInput!) {
    updatePulseDevNcrFilters(input: $input) {
      success
      message
    }
  }
`
