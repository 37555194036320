import { ModuleConfig } from 'frontend/constants'
import Color from 'frontend/utils/color'

import Administrator from './Administrator'

export const config: ModuleConfig = {
  title: 'Administrator',
  description: 'Admin-only application tools.',
  iconColor: Color.PAYER_PROJECTS,
  name: 'administrator',
  to: '/administrator',
  exact: false,
  iconId: 'admin-white',
  activeColor: Color.PAYER_PROJECTS,
  featureFlag: 'shouldShowAdminModule',
  isAdminOnly: true,
  Component: Administrator,
}
