import { useMutation } from '@apollo/client'

import tag from './gql-tag'

const useResetPassword = () =>
  useMutation(tag, {
    onCompleted: () => {
      alert(
        'An email has been sent to this user to reset their password. If the user fails to receive the email, you will be sent an email with a link to give to the user.'
      )
    },
    onError: () => {
      alert('Failed to reset password. Please try again.')
    },
  })

export default useResetPassword
