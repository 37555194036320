import React from 'react'
import styled from '@emotion/styled'

import NavigationLinks from 'frontend/components/NavigationLinks'
import { Colors, Spacing } from 'frontend/utils/pulseStyles'
import Color from 'frontend/utils/color'

import { ADMINISTRATOR_DATA_CONFIG } from './sidebar-link-utils'
import { StyledModuleSidebarHeader } from 'frontend/shared/StyledModuleSidebarHeader'

const SidebarContainer = styled.div`
  width: 300px;
  min-width: 300px;
  background-color: ${Colors.NAVY};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  @media (max-width: 1200px) {
    width: 256px;
    min-width: 256px;
  }
`

const Header = styled.div({
  alignItems: 'center',
  // background: transparentize(0.1, Colors.BLACK),
  color: Color.ADMIN,
  display: 'flex',
  fontSize: 12,
  fontWeight: 700,
  padding: `${Spacing.MEDIUM} ${Spacing.EXTRA_LARGE}`,
  textTransform: 'uppercase',
})

const Logo = styled.img({
  display: 'inline',
  marginRight: Spacing.SMALL,
})

const Sidebar = () => {
  return (
    <SidebarContainer>
      <Header>
        <Logo src="https://res.cloudinary.com/pulsedatatools/image/upload/v1743100593/polaris/icons/admin-color.svg" />
        <StyledModuleSidebarHeader>Admin</StyledModuleSidebarHeader>
      </Header>
      <div style={{ padding: `0 0 24px` }}>
        <NavigationLinks
          sectionHeader="Administrator"
          linkConfig={ADMINISTRATOR_DATA_CONFIG}
        />
      </div>
    </SidebarContainer>
  )
}

export default Sidebar
