import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

interface Props {
  header: String | React.ReactNode
}

const Wrapper = styled.div({
  marginTop: 8,
  borderBottom: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
})

const Header = styled.div({
  fontSize: 20,
  fontWeight: 600,
  padding: 24,
})

export const PanelHeader = ({ header }: Props) => {
  let headerComponent
  if (typeof header === 'string') {
    headerComponent = <Header>{header}</Header>
  } else {
    headerComponent = header
  }

  return <Wrapper>{headerComponent}</Wrapper>
}
