import React from 'react'

import { LabelCheckbox } from 'frontend/components'

interface Props {
  module: any
  selectedModules: any[]
  onChange: any
}

export const Checkbox = ({ module, selectedModules, onChange }: Props) => {
  const checked = selectedModules.includes(module.name)

  const handleChange = ({ target: { checked, name } }: any) => {
    const updatedModules = checked
      ? [...selectedModules, name]
      : selectedModules.filter((moduleName) => moduleName !== name)

    onChange({ value: updatedModules })
  }

  return (
    <LabelCheckbox
      checked={checked}
      label={module.title}
      name={module.name}
      onChange={handleChange}
    />
  )
}
