import React, { useState } from 'react'

import { Label, Wrapper } from '../shared'
import { Checkbox } from './Checkbox'
import { Input } from './Input'
import { ResetButton } from './ResetButton'

interface Props {
  fieldState: any
  onChange: any
}

const LABEL_TEXT = 'Password'

export const Password = ({ fieldState, onChange }: Props) => {
  const { user_id: userId } = fieldState
  const [isManualPassword, setIsManualPassword] = useState(false)

  const toggleCheckbox = ({ target: { checked } }: any) => {
    if (!checked) onChange({ name: 'password', value: undefined })
    setIsManualPassword(checked)
  }

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <div style={{ display: 'flex', gap: 12 }}>
        <ResetButton userId={userId} />
        {/* <GetResetUrlButton
          userId={userId}
          resetPasswordUrl={resetPasswordUrl}
        /> */}
      </div>
      <Checkbox checked={isManualPassword} onChange={toggleCheckbox} />
      <Input
        value={fieldState['password']}
        isManualPassword={isManualPassword}
        onChange={onChange}
      />
      {/* {resetPasswordUrl.data[userId] && (
        <div
          style={{
            overflow: 'scroll',
            width: 250,
            borderRadius: 6,
            padding: 12,
            margin: 12,
            background: Colors.GRAY_300,
            fontSize: 12,
          }}
        >
          {resetPasswordUrl.data[userId]}
        </div>
      )} */}
    </Wrapper>
  )
}
