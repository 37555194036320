import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors, Spacing } from '../../utils/pulseStyles'

const StyledNavLink = styled(NavLink)({
  margin: `0 ${Spacing.NORMAL}`,
  borderRadius: 4,
  padding: `${Spacing.SMALL} ${Spacing.NORMAL}`,
  textDecoration: 'none',

  // Shared Styles
  fontSize: 11,
  fontWeight: 400,
  letterSpacing: '0.2px',
  lineHeight: '18px',
  ':hover': {
    background: transparentize(0.9, Colors.WHITE),
  },
})

const defaultInactiveStyle = {
  color: transparentize(0.2, Colors.WHITE),
  padding: `${Spacing.SMALL} ${Spacing.NORMAL}`,
}

const defaultActiveStyle = {
  color: Colors.WHITE,
  fontWeight: 700,
  background: transparentize(0.9, Colors.WHITE),
  padding: `${Spacing.SMALL} ${Spacing.NORMAL}`,
}

const NavigationLink = ({ key, link, label, activeStyle, style, exact }) => {
  const combinedActiveStyle = { ...defaultActiveStyle, ...activeStyle }
  const combinedStyle = { ...defaultInactiveStyle, ...style }

  return (
    <StyledNavLink
      key={key}
      to={link}
      exact={exact}
      activeStyle={combinedActiveStyle}
      style={combinedStyle}
    >
      {label}
    </StyledNavLink>
  )
}

NavigationLink.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
  activeStyle: PropTypes.object,
  style: PropTypes.object,
  exact: PropTypes.bool,
  key: PropTypes.string,
}

NavigationLink.defaultProps = {
  label: '',
  link: null,
  activeStyle: {},
  style: {},
  exact: false,
  key: '',
}

export default NavigationLink
