import React, { MutableRefObject, useRef } from 'react'

import { SlidingPanel } from 'frontend/components'
import { useClient, useTeam } from 'frontend/entity-hooks/ember'
import { useQueryString, useClearSearchStringParam } from 'frontend/hooks'

import { getClientOptions } from '../utils/getClientOptions'
import { getFormConfig } from './getFormConfig'

const HEADER_TEXT = 'Edit Team'

export const EditTeamSidePanel = () => {
  const clearSearchStringParam = useClearSearchStringParam()
  const { team: selectedTeam } = useQueryString()
  const { data: clients, isLoading: areClientsLoading } = useClient()
  const {
    data: team,
    update,
    isLoading: isTeamLoading,
    options: clientSourceOptions,
    areOptionsLoading,
  }: {
    data: any
    update: any
    options: any
    isLoading: Boolean
    areOptionsLoading: Boolean
  } = useTeam({
    queryInput: { id: selectedTeam },
  })

  const inputRef: MutableRefObject<any> = useRef({})
  const handleSubmit = () => {
    const input = inputRef.current
    update({ variables: { input: { id: selectedTeam, ...input } } })
  }
  const handleCancel = () => {
    clearSearchStringParam(['sidePanel'])
  }

  const setInputRef = ({ name, value }: any) => {
    inputRef.current[name] = value
  }

  const clientOptions = getClientOptions(clients, areClientsLoading)
  const formConfigOptions = {
    clientOptions,
    clientSourceOptions: clientSourceOptions.client_source?.options ?? [],
  }
  const formConfig = getFormConfig(formConfigOptions)

  const isLoading = areOptionsLoading || areClientsLoading || isTeamLoading

  return (
    <SlidingPanel
      header={HEADER_TEXT}
      formConfig={formConfig}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      setInputRef={setInputRef}
      isLoading={isLoading}
      datum={team[0]}
    />
  )
}
