export const ADMINISTRATOR_DATA_CONFIG = [
  {
    label: 'Polaris User Management',
    link: '/administrator/polaris-user-mgmt',
  },
  {
    label: 'Lists Config Management',
    link: '/administrator/lists-config-mgmt',
  },
  {
    label: 'Sheet Management',
    link: '/administrator/sheet-mgmt',
  },
  {
    label: 'Node Management',
    link: '/administrator/node-management',
  },
  {
    label: 'Push Dev to Prod Management',
    link: '/administrator/push-dev-prod-mgmt',
  },
  {
    label: 'Business Object',
    link: '/administrator/business-object',
    childLinks: [
      {
        label: 'Business Object Management',
        link: '/business-object-mgmt',
      },
      {
        label: 'Business Object Modal Management',
        link: '/business-object-modal-mgmt',
      },
    ],
  },
  {
    label: 'Edit Role Node',
    link: '/administrator/edit-role-node',
  },
  {
    label: 'End-User Terms Management',
    link: '/administrator/user-term-mgmt',
  },
  {
    label: 'Total History',
    link: '/administrator/total-history',
  },
]
