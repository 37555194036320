import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import BusinessObjectManagement from './BusinessObjectManagement'
import BusinessObjectModalManagement from './BomManagement'

const BusinessObject = () => (
  <Switch>
    <Route
      path={'/administrator/business-object/business-object-mgmt'}
      component={BusinessObjectManagement}
    />
    <Route
      path={'/administrator/business-object/business-object-modal-mgmt'}
      component={BusinessObjectModalManagement}
    />
    <Redirect to={'/administrator/business-object/business-object-mgmt'} />
  </Switch>
)

export default BusinessObject
