import React from 'react'

import SidePanel from './SidePanel'
import SlidingPane from './SlidingPane'

interface Props {
  header: string | React.ReactNode
  formConfig?: any
  handleSubmit: Function
  handleCancel: Function
  setInputRef?: Function
  isLoading?: Boolean
  datum?: any
  children?: any
  disableSubmit?: boolean
}

export const SlidingPanel = ({
  header,
  formConfig,
  handleSubmit,
  handleCancel,
  setInputRef,
  isLoading,
  datum,
  children,
  disableSubmit,
}: Props) => {
  return (
    <SlidingPane>
      <SidePanel
        header={header}
        formConfig={formConfig}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        setInputRef={setInputRef}
        isLoading={isLoading}
        datum={datum}
        childForm={children}
        disableSubmit={disableSubmit}
      />
    </SlidingPane>
  )
}
