import {
  DISPLAY_CELL_TYPES,
  SORT_TYPES,
  FILTER_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'
import {
  DateMultiSelectFilter,
  SingleSelectFilter,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'

import { formatMonthDayYearSlash } from 'frontend/utils/formatDate'

import { useRewriteSearchString } from 'frontend/hooks'

const { DATE_MULTI_SELECT, SINGLE_SELECT } = FILTER_TYPES
const { BUTTON, CONDENSED_EXPAND } = DISPLAY_CELL_TYPES
const { STRING, DATE } = SORT_TYPES

export const ROLE_OPTIONS = [
  { value: 'admin', label: 'Admin' },
  { value: 'manager', label: 'Manager' },
  {
    value: 'operator',
    label: 'Operator',
  },
]

const ROLE_MAP = {
  admin: 'Admin',
  manager: 'Manager',
  operator: 'Operator',
}

export const ORGANIZATION_OPTIONS = [
  { value: 'pulse', label: 'PULSE' },
  { value: 'tdg', label: 'TDG' },
  { value: 'mmit', label: 'MMIT' },
]

const ORGANIZATION_MAP = {
  pulse: 'PULSE',
  tdg: 'TDG',
  mmit: 'MMIT',
}

const useColConfig = ({ handleDelete }) => {
  const rewriteSearchString = useRewriteSearchString()

  const displayCellConfig = {
    type: CONDENSED_EXPAND,
    onClick: ({ user_id }) => {
      rewriteSearchString({ sidePanel: true, id: user_id })
    },
  }

  return [
    {
      accessor: 'picture',
      width: 20,
      displayCellConfig,
    },
    {
      Header: 'Name',
      accessor: 'name',
      sortType: STRING,
      displayCellConfig,
      Filter: SingleSelectFilter,
      filter: SINGLE_SELECT,
    },
    {
      Header: 'Email',
      accessor: 'email',
      sortType: STRING,
      displayCellConfig,
      Filter: SingleSelectFilter,
      filter: SINGLE_SELECT,
    },
    {
      Header: 'Username',
      accessor: 'username',
      sortType: STRING,
      displayCellConfig,
      Filter: SingleSelectFilter,
      filter: SINGLE_SELECT,
    },
    {
      Header: 'Role',
      accessor: 'role',
      sortType: STRING,
      displayCellConfig,
      labelMap: ROLE_MAP,
      Filter: SingleSelectFilter,
      filter: SINGLE_SELECT,
    },
    {
      Header: 'Organization',
      accessor: 'organization',
      sortType: STRING,
      displayCellConfig,
      labelMap: ORGANIZATION_MAP,
      Filter: SingleSelectFilter,
      filter: SINGLE_SELECT,
    },
    {
      Header: 'Last Login Date',
      accessor: 'last_login',
      sortType: DATE,
      displayCellConfig,
      cellValueFormatter: formatMonthDayYearSlash,
      Filter: DateMultiSelectFilter,
      filter: DATE_MULTI_SELECT,
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      sticky: 'left',
      displayCellConfig: {
        type: BUTTON,
        label: 'Delete',
        onClick: handleDelete,
      },
    },
  ]
}

export default useColConfig
