import React from 'react'

import { APPLICATION_MODULES } from 'frontend/modules'
import { Checkbox } from './Checkbox'

interface Props {
  selectedModules: any[]
  onChange: any
}

export const CheckboxList = ({ selectedModules, onChange }: Props) => {
  return (
    <>
      {APPLICATION_MODULES.map((module: any) => (
        <Checkbox
          module={module}
          selectedModules={selectedModules}
          onChange={onChange}
        />
      ))}
    </>
  )
}
