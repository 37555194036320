import { useAuth0 } from 'react-auth0-spa'
import React, { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import Button from 'frontend/components/Button'
import { UPDATE_PULSE_DEV_NCR_FILTERS } from 'frontend/api/mutations'

const NcrFiltersAirflowButton = () => {
  const { user } = useAuth0()

  const [updatePulseDevNcrFilters, { loading, error }] = useMutation(
    UPDATE_PULSE_DEV_NCR_FILTERS
  )

  const handleClick = useCallback(async () => {
    try {
      const response = await updatePulseDevNcrFilters({
        variables: {
          input: {
            user,
          },
        },
      })

      if (response.data.updatePulseDevNcrFilters.success) {
        alert('NCR Filters DAG Triggered Successfully!')
      } else {
        alert('Failed to trigger NCR Filters DAG.')
      }
    } catch (err) {
      console.error('Error triggering DAG:', err)
      alert('Failed to trigger NCR Filters DAG.')
    }
  }, [updatePulseDevNcrFilters, user])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: 300 }}>
      <p style={{ marginBottom: 12 }}>
        Trigger the airflow <b>AFTER</b> you’ve imported NCR mapping data
      </p>
      <Button onClick={handleClick} type="secondary" isDisabled={loading}>
        {loading ? 'Triggering...' : 'Trigger NCR Filters Airflow'}
      </Button>
      {error && <p style={{ color: 'red' }}>Error triggering DAG.</p>}
      <a
        href="https://45c81e40-9188-46d2-8e0e-9f6ebe66b2f2.c5.us-east-1.airflow.amazonaws.com/dags/ncr-materialize-ncr-filters/grid"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline', marginTop: 12 }}
      >
        Click here to see the status of the Airflow job!
      </a>
    </div>
  )
}

export default NcrFiltersAirflowButton
