import React from 'react'
import { DataTable } from '@pulse-analytics/pulse-design-system'
import styled from '@emotion/styled'

import PanelHeader from 'frontend/components/Panel/PanelHeader'
import { usePolarisUsers } from 'frontend/api/usePolarisUsers/usePolarisUsers'

import SidePanel from './SidePanel'
import CreateButton from './CreateButton'
import useColConfig from './useColConfig'

const ICON_BAR_WIDTH = 60
const ORION_SIDEBAR_WIDTH = 300

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: `calc(100vw - ${ICON_BAR_WIDTH + ORION_SIDEBAR_WIDTH}px)`,
})

const Avatar = styled.img({
  clipPath: `circle(20px at center)`,
  width: 30,
})

const PAGE_TITLE = 'Polaris User Management'

const PolarisUserManagement = () => {
  const { data, isLoading, delete: destroy } = usePolarisUsers()

  const handleDelete = ({ name, user_id }) => {
    const shouldDelete = window.confirm(`Delete polaris user, ${name}?`)
    if (shouldDelete) {
      destroy({ variables: { input: { user_id } } })
    }
  }

  const formattedData = data.map(
    ({ app_metadata, user_metadata, picture, ...datum }) => {
      return {
        ...datum,
        role: app_metadata?.role,
        organization: app_metadata?.organization,
        permitted_modules: app_metadata?.permitted_modules || [],
        initials: user_metadata?.initials,
        picture: (
          <Avatar src={user_metadata?.picture || picture} alt={datum.name} />
        ),
      }
    }
  )

  const colConfig = useColConfig({
    handleDelete,
  })

  return (
    <Container>
      <SidePanel />
      <PanelHeader title={PAGE_TITLE} />
      <DataTable
        isPaginated
        paginationConfig={{
          wrapperStyle: { alignSelf: 'flex-start' },
          pageSize: 50,
        }}
        tableId={'polaris-users-table'}
        isLoading={isLoading}
        data={formattedData}
        colConfig={colConfig}
        utilityBarNodes={[<CreateButton />]}
        wrapperStyle={{ zIndex: 0 }}
      />
    </Container>
  )
}

export default PolarisUserManagement
