import React, { Dispatch, SetStateAction } from 'react'

import { LabelCheckbox } from 'frontend/components'

interface Props {
  team: any
  selectedTeams: [any]
  setFieldState: Dispatch<SetStateAction<any>>
}

export const Checkbox = ({ team, selectedTeams, setFieldState }: Props) => {
  let checked = selectedTeams.filter(({ id }: any) => id === team.id).length > 0
  const handleChange = ({ target: { checked, name } }: any) => {
    setFieldState(
      ({
        teams,
        primaryTeam,
        isCustomOncologyKdmToolScorer,
        ...fieldState
      }: any) => {
        const newTeam = {
          id: team.id,
          name: team.name,
          default_landing_page: team.default_landing_page,
        }
        if (checked) teams = [...teams, newTeam]
        else {
          teams = teams.filter(({ id }: any) => id !== name)
          if (name === primaryTeam) {
            primaryTeam = null
            isCustomOncologyKdmToolScorer = false
          }
        }

        return {
          teams,
          primaryTeam,
          isCustomOncologyKdmToolScorer,
          ...fieldState,
        }
      }
    )
  }

  return (
    <LabelCheckbox
      checked={checked}
      label={team.name}
      name={team.id}
      onChange={handleChange}
    />
  )
}
