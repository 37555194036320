import React from 'react'

import { Select } from 'frontend/components'

import { ROLE_OPTIONS } from '../../useColConfig'
import { Label, Wrapper } from './shared'

interface Props {
  fieldState: any
  onChange: any
}

const LABEL_TEXT = 'Role'
const FIELD_NAME = 'app_metadata.role'

export const Role = ({ fieldState, onChange }: Props) => {
  const { app_metadata } = fieldState
  const role = app_metadata?.role
  const defaultValue = ROLE_OPTIONS.find(({ value }: any) => value === role)

  const handleChange = ({ value }: any) => {
    onChange({
      name: FIELD_NAME,
      value,
    })
  }

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Select
        options={ROLE_OPTIONS}
        value={defaultValue}
        onChange={handleChange}
      />
    </Wrapper>
  )
}
