import { ModuleConfig } from 'frontend/constants'
import Color from 'frontend/utils/color'


import ClientManagement from './ClientManagement'

export const config: ModuleConfig = {
  title: 'Client Management',
  description: 'User Management and Tool Permissions',
  iconColor: Color.PHOENIX,
  name: 'client_management',
  to: '/client-management',
  exact: false,
  iconId: 'phoenix-1-white',
  activeColor: Color.PHOENIX,
  Component: ClientManagement,
}
