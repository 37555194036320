import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@pulse-analytics/pulse-design-system'

import { usePolarisUsers } from 'frontend/api'

const BUTTON_STYLE = {
  padding: '5px 8px',
}

const Wrapper = styled.div({
  marginBottom: 2,
})

export const ResetButton = ({ userId }) => {
  const { resetPassword } = usePolarisUsers(userId)

  const input = { user_id: userId }

  return (
    <Wrapper>
      <Button
        onClick={() => {
          resetPassword({ variables: { input } })
        }}
        style={BUTTON_STYLE}
      >
        Send Reset Password Email
      </Button>
    </Wrapper>
  )
}
